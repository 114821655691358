

.form-signin {
    width: 100%;
    max-width: 90%;
    padding: 15px;
    margin: auto;
}

.invalid {
    border-width: 0.1rem !important;
    border-color: #dc3545 !important;
}

.alert-dismissible .close{
    padding: 0.50rem 1.25rem !important;
}