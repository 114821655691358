/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:25
 * @modify date 06-02-2020 12:25
 * @description This css file has custome css defined for the Application.
 */

select, option{
  cursor: pointer;
}

.form-control::-ms-clear {
  display: none;
}
.react-dropdown-select-input::-ms-clear {
  display: none;
}

:focus {
  outline: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control:focus{
  border-color: #ced4da !important;
  box-shadow:none !important;
}

.btn-sm, .btn-group-sm > .btn {
  line-height: 1.3 !important;
}

.btn-secondary:disabled, .btn-outline-primary:disabled, .btn-primary:disabled{
  cursor: not-allowed !important;
}

.round-help-btn {
  border-radius: 50%;
}

/* .card {
  height: 100% !important;
} */

.card-content-height {
  width: 100% !important;
  height:calc(100vh - 155px) !important;
}

.health{
  border-radius: 50%;
  width: 18px !important;
  height: 18px !important;
  padding: 4px !important;
}

.health-warning {
  color: #ffc107 !important;
}

.services{
  border-radius: 50%;
  width: 18px !important;
  height: 18px !important;
  padding: 4px !important;
}

.services-warning {
  color: #ffc107 !important;
}

.css-1wa3eu0-placeholder{
  top:39% !important;
}

.css-yk16xz-control{
  height: 34px !important;
  min-height: 30px !important;
}

.css-2b097c-container:focus{
  outline: none;
}

.react-bootstrap-daterangepicker-container .form-control{
  height: calc(1.7em + 0.5rem + 2px) !important;
  font-size: inherit;
  padding: 0.25rem 0.5rem;
  border-radius: 0;
}

.rbt-input-hint{
  z-index: -1 !important;
}

.rbt .form-control{
  /* height: 34px !important; */
  font-size: inherit
}

.ReactTable .rt-noData{
  z-index: initial !important;
}

.rbt-aux {
  display: flow-root;
  /* margin-top: -30px; */
  margin-right: 5px;
}

.rbt-loader {
  margin-top: 2px !important;
  margin-left: 11px !important;
  height: 22px !important;
  width: 22px !important;
}

.rbt-aux .rbt-close{
  margin-top: 0px !important;
}

.dropdown-menu{
  font-size:inherit !important;
}

.dropdown-item{
  padding: 0.25rem 0.5rem !important;
}

.dashboard-widget-title{
  font-size: 0.75rem !important;
/* color: white; */
}

.dashboard-widget-count-text{
  padding: 15px 0px;
  color: white;
}

.bg-card-tile{
  background-color: #f5f6f7;
}

.bg-card-tile:hover{
  background-color: #e5e5e6;
}

.toast-position-index{
  z-index: 2 !important;
}

.table thead th{
  background: white !important;
  vertical-align: middle !important;
}

.react-bootstrap-table td>.selection-input-4, .react-bootstrap-table th>.selection-input-4 {
  margin: 2px 2px !important;
}

.react-bootstrap-table tbody {
  overflow: auto !important;
  min-height: 400px !important;
  max-height: 400px !important;
}

.react-bootstrap-table tbody tr {
  min-height: 25px !important;
  max-height: 50px !important;
}

.table thead tr th  {
  position: sticky !important;
  top: 0 !important;
}

table{
  border-collapse:inherit !important;
}

.search-label .form-control{
  font-size: 0.85rem !important;
}
hr{
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-title-drill-down{
  margin-top: 0.75rem !important; 
  margin-bottom: 0.75rem !important;
}

.dropdown-no-match{
  margin: 5px 10px;
  cursor: initial;
}

.dropdown-item-cursor{
  cursor: pointer !important;
}

#chart {
  background: #fff;
  width: 90%;
  margin: 1em auto;
}

.c3-chart-arcs-title{
  font-size: 4.3em !important;
}

.c3-grid .c3-xgrid-focus {
  visibility : hidden !important;
}

.c3-axis-y-label{
  font-size: 0.85rem !important;
}

.c3-axis-x-label{
  font-size: 0.85rem !important;
}

.tooltip-fix-width-150{
  width: 150px !important;
}

.tooltip-fix-width-200{
  width: 200px !important;
}

.tooltip-fix-width-300{
  width: 300px !important;
}

.hide-bottom-border-modal-header{
  border-bottom: none !important;
}

.hide-top-border-modal-footer{
  border-top: none !important;
}

.react-dropdown-select-content{
  overflow-y: auto;
  max-height: 100px !important;
}

.react-dropdown-select-content-search > :first-child {
  max-height: 40px !important;
}

.react-dropdown-select-clear{
  font-size: 1.15rem !important;
  margin: 0px !important;
  margin-top: -4px !important;
  padding: 0 10px !important;

}

.react-dropdown-select-content-custom{
  min-height: 30px !important;
}
/* .react-dropdown-select-content::-webkit-scrollbar{
  display: none;
} */
.modal-body .react-dropdown-select-dropdown{
  top:35px !important;
  width: 100% !important;
}

.react-dropdown-select-content::-webkit-scrollbar {
  width: 2px;
  position: absolute;
}
  
/* Track */
.react-dropdown-select-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.react-dropdown-select-content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.react-dropdown-select-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-control:disabled{
  cursor: initial !important;
}

.sophos-icon-size{
  width: 30px;
  height: 30px;
  display: inline-block;
}

.link-style{
  color: #007bff !important;
}

.link-style:hover{
  color: #007bff !important;
  cursor:pointer; 
  text-decoration: underline !important;
}

.json-error-viewer tr:hover{
  background-color: #cecbcb !important;
  cursor: default !important;
}

.auditlogs .table-bordered tr:hover{
  background-color: #cecbcb;
  cursor: initial !important;
}

.json-error-viewer tbody tr:nth-of-type(2n+1):hover{
  background-color:#cecbcb !important;
  cursor: default !important;
}