.info-container {
    background: #005BC81A;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
}

.message-wrapper {
    padding: 0;
    display: block;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.message-icon {
    margin-right: 1rem;
    font-size: 14px;
}

.deploymentGuide-body {
    font-size: 14px;
    padding: 20px;
}