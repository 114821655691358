/** File Uploader CSS */

.file-uploader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .icon-text-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-width: 2px;
      border-style: dashed;
      border-color: #444;
      width: 100%;
      height: 100%;
      text-shadow: 0px 0px 0 #999;
      transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;
      .upload-icon {
         width: 50px;
         height: 50px;
         margin: 5px;
         box-sizing: border-box;
         transform: translateY(0px);
         transition: transform 0.2s ease-in-out;
         i.fa.fa-upload {
            font-size: 40px;
         }
      }
      .upload-text {
        margin: 5px;
     }
      .upload-button, .cancel-upload-button {
         margin: 0 10px;
         position: relative;
         z-index: 20;
      }
     
      .error-notification {
         animation: fadeInDown 0.5s;
         position: absolute;
         top: 20px;
         background-color: #dfb852;
         border-radius: 5px;
         color: #444;
         padding: 5px 10px;
         p {
            margin: 0;
            text-shadow: none;
         }
      }
   }
    .display-box {
       position: relative;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       background-color: #fbfbfb;
       height: calc(100vh - 440px);
       width: 100%;
       padding: 10px;
       &:hover, &.drag-over {
          .icon-text-box {
             background-color: #96a599;
             cursor: pointer;
             color: #fff;
             text-shadow: 0px 1px 0 #999;
          }
       }
       .upload-file-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
       }
    }
    @media (min-width: 768px) {
      //  .display-box {
         //  width: 600px;
      //  }
    }

    .disable-upload {
       @extend .display-box;
       background-color: #cdcbcd;
       &:hover, &.drag-over {
         .icon-text-box {
            background-color: #cdcbcd;
            cursor: default;
            color: #212529;
            text-shadow: none;
          }
       }
       .upload-file-input {
          cursor: default;
       }
    }
 }