/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 17:53
 * @modify date 06-02-2020 17:53
 * @description This file contains the custom css for the Overview page
 */
.tab-content .jumbotron {
    background-color: #055bb5 !important;
}

// .jumbotron h6 {
// color: #fff !important;
// }

.jumbotron .icon-color {
    color: #0072bc !important;
}

.nav-tabs .nav-link {
    font-weight: 500 !important;
}

.thumbnai-list-item {
    width: 225px !important;
}

.overview-badge-round-thumbnail {
    color: #fff;
    height: 150px;
    width: 150px;
    background-color: #a6b0afa6;
    border-color: #464545;
    border-width: 10px;
    border-radius: 50%;
    display: inline-block;
    border-style: solid;
}

.overview-badge-round-thumbnail h2 {
    color: #585858 !important;
}

.overview:hover {
    cursor: initial !important;
}

.list-group-item {
    background-color: transparent !important;
    border: none !important;
}

.alignment-update {
    margin-left: -1.7rem;
    margin-right: -1rem;
}

.oval {
    border-radius: 10px;
}

.pipe-color {
    color: #DADCE0;
}