.filter-container {
    display: flex;
    align-items: flex-end;

    .radio-container {
        display: flex;
        flex-direction: column;

        .radio-buttons {
            display: flex;
            flex-direction: row;
        }
    }

    .divider {
        display: flex;
        height: 2.3rem;
        align-self: center;

        .content {
            border-left: 1px solid #B3B6BB;
        }
    }

    .filter-icon {
        color: #B3B6BB;
        font-size: 20px;
        margin: 1rem;
    }

    label {
        color: #696A6B;
        font-size: 14px;
        margin-left: 0;
        text-align: left;
    }

    .text-filters {
        display: flex;
        column-gap: 2rem;
        align-items: flex-end;
    }

    .multiselect-box {
        width: 20rem;
        color: #696A6B;
        font-size: 14px;
        margin-left: 0;
        text-align: left;
    }

    .link {
        font-size: 14px;
    }

    .link-container {
        margin-left: auto;
    }
}

.link-button {
    font-size: 14px;
    padding-left: 0;
    margin-bottom: 2px;
    color: #005BC8;
    font-weight: bold;
    cursor: pointer;

    &:active,
    &:enabled,
    &:focus,
    &:hover,
    &:focus-visible {
        box-shadow: none;
        color: #005BC8;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-top: 2rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.3);

    .button-container {
        display: flex;
        column-gap: 2rem;

        .btn-padding {
            padding: .2rem 3rem;
        }
    }

    .notes {
        display: flex;
        font-size: 12px;

        .align-list {
            padding-left: 1rem;
        }
    }

    .align-spinner {
        margin-left: 1rem;
    }
}