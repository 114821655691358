/*step-wizard-bar*/
#step-wizard-bar {
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
}

#step-wizard-bar li {
    list-style-type: none;
    color: #6c757d;
    font-size: 12px;
    width: 33.33%;
    float: left;
    position: relative;
    text-align: center;
}

#step-wizard-bar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    line-height: 26px;
    display: block;
    font-size: 12px;
    color: white;
    background: #b2b2b2;
    border-radius: 25px;
    margin: 0 auto 10px auto;
    z-index: 1;
}

/*step-wizard-bar connectors*/
#step-wizard-bar li:after {
    content: '';
    width: 90%;
    height: 2px;
    background: #b2b2b2;
    position: absolute;
    left: -45%;
    top: 9px;
    z-index: 0; /*put it behind the numbers*/
}

#step-wizard-bar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#step-wizard-bar li.active:before, #step-wizard-bar li.active:after {
    background: #007bff;
    color: white;
}

.btn-label {
    position: relative;
    display: inline-block;
    padding: 6px 12px;
    background: rgba(0,0,0,0.15);
    border-radius: 3px 0 0 3px;
}
.btn-labeled-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    .btn-label{
        margin-right: 12px;
    }
}
.btn-labeled-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    .btn-label{
        margin-left: 12px;
    }
}
.form-group .btn { 
    margin-bottom:10px; 
}