.row-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 2rem;
    color: #696A6B;
    font-size: 14px;

    .first-row {
        justify-content: space-between;
        display: flex;
        label{
            font-weight: bold;
        }
    }

    .row-items {
        display: flex;
        width: 85vw;
    }
    .required{
        color:red;
    }
}