.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc{
    box-shadow: none !important;
    &:before {
        content: "▲";
        float: right;
    }
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc{
    box-shadow: none !important;
    &:before {
        content: "▼";
        float: right;
    }
}

.ReactTable .rt-thead .rt-tr {
    text-align: center;
    margin-right: 17px !important;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    // padding: 20px 10px;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }

.table-bordered tr:hover{
  background-color: #cecbcb;
  cursor: pointer;
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #cecbcb;
  cursor: pointer;
}
  

.badge {
    padding: 4px !important;
}

/*
*  position: relative gives a new position context
*/
.table-wrapper {
  position: relative;
}

.react-bootstrap-table {
  border: 1px solid #dee2e6;
  height: calc(100vh - 270px) !important;
  overflow: auto !important;
}

.react-bootstrap-table td.react-bs-table-no-data{
  background-color: #cecbcb !important;
  cursor:initial !important;
}


.react-bootstrap-table th .caret-4-asc:before {
  content: "\2191";
  font-size: 15px;
}

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193";
  font-size: 15px;
}

.react-bootstrap-table th .order-4:before, .caret-4-desc:before{
  content: "\2191";
  opacity: .4;
  font-size: 15px;
}

.react-bootstrap-table th .order-4:after, .caret-4-asc:after{
  content: "\2193";
  opacity: .4;
  font-size: 15px;
}

/*
*  Position the #infoi element in the top-right
*  of the .wrapper element
*/
.loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  /*
  *  Styling only, the below can be changed or removed
  *  depending on your use case
  */
  height: calc(100vh - 270px) !important;
  width: 100% !important;
  padding: 10px 10px !important;
}

.loading-wrapper .spinner-loader{
  margin-top: 10% !important;
  text-align: center !important;
}

.clear-search-icon{
    cursor: pointer;
    transition: all 300ms ease;
    width: 22px;
    height: 34px;
    border: none;
    background-color: transparent;
    outline: none;
    right: 1px;
    top: 15px;
    position: absolute;
    margin: 0;
    padding: 0;
}


.clear-search-icon-dropdown{
  cursor: pointer;
  transition: all 300ms ease;
  width: 46px;
  height: 70px;
  border: none;
  background-color: transparent;
  outline: none;
  right: 1px;
  top: 17px;
  position: absolute;
  margin: 0;
  padding: 0;
}

.clear-search-icon .fa-times::before{
  color:#00000080 !important
}

.clear-search-icon-dropdown .fa-times::before{
  color:#00000080 !important
}

.clear-search-icon .fa-times:hover::before{
  color:#000000b4 !important
}

.clear-search-icon-dropdown .fa-times:hover::before{
  color:#000000b4 !important
}